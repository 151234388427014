import styled from "styled-components";

import { Box } from "../Grid";

const NewsListItemStyle = styled(Box)`
  //width: calc(33.33333333% - 30px);
  margin: 0 15px 30px;
  border-bottom: 1px solid #d6d6d6;
  border-bottom: ${props => (props.showDescription ? "1px solid #d6d6d6" : 0)};
  background-color: ${props => (props.type === "promoted" ? "#eef2d6" : "white")};

  & > a {
    display: block;
    height: 100%;
    padding-bottom: 30px;
    text-decoration: none;
    color: initial;
    &:hover {
      h3 {
        color: ${props => props.theme.colors.grey.dark};
      }
    }
  }
  .content {
    padding: ${props => (props.type === "promoted" ? "0 20px" : 0)};
  }
  .image {
    width: 100%;
    position: relative;
    height: auto;
    margin-bottom: 20px;
    img {
      display: block;
      max-width: 100%;
      max-height: 22.5rem;
      margin: 0 auto;
    }
    &.empty {
      background-color: ${props => props.theme.colors.grey.extraLight};
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

NewsListItemStyle.displayName = "NewsListItemStyle";

NewsListItemStyle.defaultProps = {
  width: ["100%", "calc(50% - 30px)", null, "calc(33.33333333% - 30px)"],
};

export { NewsListItemStyle };
