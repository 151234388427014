import React, { useEffect } from "react";
import { NewsListStyle } from "../../style/News";
import classNames from "classnames";
import { Text } from "../../style/Text";
import { formattedDate } from "../../helpers";
import { Heading } from "../../style/Heading";
import { NewsListItem } from "./NewsListItem";
import UniversalLink from "../Navigation/UniversalLink";

const NewsList = ({ articles, onShow, showDate, style, showDescription }) => {
  useEffect(() => {
    onShow && onShow();
  }, []);

  return (
    <NewsListStyle>
      {articles &&
        articles.map((item, index) => {
          const { name, releaseDate, perex, promolink, descriptionImage, type, slug } = item.node;

          return (
            <NewsListItem type={type && type} key={index} style={style} showDescription={showDescription}>
              <UniversalLink to={type === "promoted" ? promolink : `/novinky/${slug}/`}>
                <div
                  className={classNames({
                    image: true,
                    empty: !descriptionImage,
                  })}
                >
                  {descriptionImage ? <img src={`${descriptionImage}`} alt="" /> : <img src={require("../../images/MND.cz.svg")} alt="" />}
                </div>
                <div className="content">
                  {showDate && (
                    <Text as="p" fontSize={1} mb={3} color="grey.light">
                      {formattedDate(releaseDate.date).toUpperCase()}
                    </Text>
                  )}
                  <Heading as="h3" fontSize={4} color="green.dark" mb={3}>
                    {name}
                  </Heading>
                  {perex && showDescription && (
                    <Text mb={0} color={"grey.dark"}>
                      {perex}
                    </Text>
                  )}
                </div>
              </UniversalLink>
            </NewsListItem>
          );
        })}
    </NewsListStyle>
  );
};

NewsList.defaultProps = {
  showDescription: true,
};

export { NewsList };
